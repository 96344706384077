import React, { useEffect, useState } from 'react';
import { Pagination } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store/store';
import {
  deleteImage,
  getAllImages,
  removeImage,
  setPage,
  setUpdateImageData,
} from '../../redux/features/admin/gallery/gallerySlice';
import binSvg from '../../assets/icons/bin.svg';
import editSvg from '../../assets/icons/edit.svg';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import NewImage from './NewImages';
import addSvg from '../../assets/icons/add.svg';
import UpdateImage from './UpdateImage';
import PrimaryButton from '../../components/buttons/PrimaryButton';

function ViewImages() {
  const [openNewImage, setNewImageOpen] = useState(false);
  const handleOpenNewImage = () => setNewImageOpen(true);
  const handleCloseNewImage = () => setNewImageOpen(false);

  const [openUpdateImage, setUpdateImageOpen] = useState(false);
  const handleOpenUpdateImage = () => setUpdateImageOpen(true);
  const handleCloseUpdateImage = () => setUpdateImageOpen(false);

  const dispatch = useDispatch<AppDispatch>();
  const galleryState = useSelector((state: RootState) => state.admin.gallery);

  const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
    dispatch(setPage(newPage));
  };

  const getEvents = async (pageNumber: number, pageSize: number) => {
    await dispatch(getAllImages({ page: pageNumber, page_Size: pageSize }));
  };

  useEffect(() => {
    getEvents(galleryState.page, galleryState.pageSize);
  }, [dispatch, galleryState.page]);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '20px' }}>
        <PrimaryButton
          style={{
            justifyContent: 'center',
            fontSize: '18px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            width: '150px',
            height: '50px',
          }}
          onClick={handleOpenNewImage}
        >
          <img src={addSvg} alt="Add icon" style={{ height: '20px', marginRight: '5px' }} />
          Add New
        </PrimaryButton>
      </div>

      <Dialog
        open={openUpdateImage}
        onClose={handleCloseUpdateImage}
        sx={{
          '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
            minWidth: '700px',
            minHeight: '400px',
          },
        }}
      >
        <DialogTitle>Update Image</DialogTitle>
        <DialogContent>
          <UpdateImage category={galleryState.updateImageData?.category_id} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdateImage}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openNewImage}
        onClose={handleCloseNewImage}
        sx={{
          '& .css-1t1j96h-MuiPaper-root-MuiDialog-paper': {
            minWidth: '700px',
            minHeight: '400px',
          },
        }}
      >
        <DialogTitle>Add New Image</DialogTitle>
        <DialogContent>
          <NewImage />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewImage}>Close</Button>
        </DialogActions>
      </Dialog>

      <div>
        {galleryState.images.map((image) => {
          const handleDeleteClick = async () => {
            await dispatch(removeImage(image.id!));
            await dispatch(deleteImage({ public_id: image.public_id!, image_id: image.id! }));
          };

          const handleUpdateClick = async () => {
            handleOpenUpdateImage();
            await dispatch(
              setUpdateImageData({
                id: image.id,
                category_id: image.category_id,
                public_id: image.public_id,
              }),
            );
            // await dispatch(deleteImage({ public_id: image.public_id!, image_id: image.id! }));
          };
          return (
            <div
              key={image.id!}
              style={{
                borderBottom: '2px solid rgba(153, 153, 153, 0.1)',
                margin: '2px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div style={{ alignItems: 'center', display: 'flex' }}>
                <img style={{ borderRadius: '10px' }} src={image.image_url!} width={'60px'} />
                <p style={{ marginLeft: '50px' }}>{image.public_id}</p>
              </div>
              <div style={{ marginRight: '20px', alignItems: 'center', display: 'flex' }}>
                <img onClick={handleUpdateClick} style={{ height: '20px' }} src={editSvg} />
                <img onClick={handleDeleteClick} style={{ marginLeft: '8px', height: '20px' }} src={binSvg} />
              </div>
            </div>
          );
        })}
      </div>
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          left: '56%',
          transform: 'translateX(-50%)',
          marginBottom: '20px',
          zIndex: 100,
        }}
      >
        <Pagination
          style={{ backgroundColor: 'white', boxShadow: 'none' }}
          count={Math.ceil(galleryState.totalItems / galleryState.pageSize)}
          page={galleryState.page}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
        />
      </div>
    </>
  );
}

export default ViewImages;
