import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import AboutUs from './pages/AboutUs/AboutUs';
import OurServices from './pages/OurServices/OurServices';
import ContactUs from './pages/ContactUs/ContactUs';
import GalleryPage from './pages/GalleryPage/GalleryPage';
import AdminPage from './pages/Admin/AdminPage';
import DashboardPage from './pages/Dashboard/Dashboard';
import AdminProtectedRoute from './pages/Protected/protected';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="" element={<HomePage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services" element={<OurServices />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/gallery" element={<GalleryPage />} />
        <Route path="/admin/login" element={<AdminPage />} />
        <Route
          path="/admin/dashboard"
          element={
            <AdminProtectedRoute>
              <DashboardPage />
            </AdminProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
