import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance, handleAxiosError } from '../../../../services/api';
import NotificationController from '../../../../services/notification';

export interface ContactState {
  files: File[];
  loading: boolean;
  message: string | null;
  error: boolean;
}

const initialState: ContactState = {
  files: [],
  loading: false,
  message: null,
  error: false,
};

const constactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Send Quote
      .addCase(sendQuote.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendQuote.fulfilled, (state) => {
        state.loading = false;
        NotificationController.success('Quotation sent!');
      })
      .addCase(sendQuote.rejected, (state) => {
        state.loading = false;
        NotificationController.error('Failed to send quote');
      })
      // Send user info
      .addCase(sendUserInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendUserInfo.fulfilled, (state) => {
        state.loading = false;
        NotificationController.success('Email sent!');
      })
      .addCase(sendUserInfo.rejected, (state) => {
        state.loading = false;
        NotificationController.error('Failed to submit data');
      });
  },
});

export default constactSlice.reducer;

export const sendQuote = createAsyncThunk(
  'contact/request-quote',
  async ({ formData }: { formData: FormData }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/web/user/contact/request-quote`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      const errorMessage = handleAxiosError(error);
      return rejectWithValue(errorMessage);
    }
  },
);

export const sendUserInfo = createAsyncThunk('contact/request', async (data: object, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(`/web/user/contact/request`, data);
    return response.data;
  } catch (error) {
    const errorMessage = handleAxiosError(error);
    return rejectWithValue(errorMessage);
  }
});
