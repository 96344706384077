import React from 'react';
import './Strip.css';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import mainTheme from '../../../../theme/theme';

interface ComponentProps {
  buttonText: string;
  onClick: () => void;
}

const Strip: React.FC<ComponentProps> = ({ buttonText, onClick }) => {
  return (
    <div className="strip-container">
      <p className="strip-text">Get a quote for your project</p>
      <PrimaryButton
        style={{
          color: mainTheme.colors.fontSecondary,
          backgroundColor: mainTheme.colors.primary,
          height: '50px',
          width: '150px',
        }}
        onClick={onClick}
      >
        {buttonText}
      </PrimaryButton>
    </div>
  );
};

export default Strip;
