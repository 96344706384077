import exp1 from '../../../../assets/images/latest-project-card-4.jpeg';
import exp2 from '../../../../assets/images/latest-project-card-1.jpeg';
import exp3 from '../../../../assets/images/latest-project-card-10.png';
import exp4 from '../../../../assets/images/latest-project-card-6.png';
import mainTheme from '../../../../theme/theme';
import './ExperienceSection.css';

function ExperienceSection() {
  return (
    <div className="experience-section-container">
      <div
        className="experience-images-container"
        style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridGap: '0px' }}
      >
        <div
          className="experience-image experience-image-1"
          style={{ display: 'flex', flexDirection: 'column', gridGap: '10px' }}
        >
          <img src={exp4} alt="icon" style={{ height: '188px', width: '233px' }} />
          <img src={exp1} alt="icon" style={{ height: 'auto', width: '233px' }} />
        </div>
        <div
          className="experience-image experience-image-2"
          style={{ display: 'flex', flexDirection: 'column', gridGap: '15px', height: '100%' }}
        >
          <img src={exp3} alt="icon" style={{ height: '130px', width: '279px' }} />
          <img src={exp2} alt="icon" style={{ height: '210px', width: '282px' }} />
        </div>
      </div>

      <div className="experience-content">
        <h1
          className="experience-title"
          style={{
            fontWeight: mainTheme.fontWeight.semiBold,
            fontFamily: mainTheme.typography.mainFont,
            color: mainTheme.colors.primary,
          }}
        >
          8 years
        </h1>
        <h1
          className="experience-subtitle"
          style={{
            fontWeight: mainTheme.fontWeight.semiBold,
            fontFamily: mainTheme.typography.mainFont,
            color: mainTheme.colors.fontPrimary,
          }}
        >
          of experience!
        </h1>
        <p className="experience-text">
        We are a local engineering company having 8 + years of experience and well known for our high quality work.
        </p>
      </div>
    </div>
  );
}

export default ExperienceSection;