import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import NotificationController from '../../../../services/notification';
import { axiosInstance, handleAxiosError } from '../../../../services/api';

interface Image {
  id?: string;
  category_id?: string;
  image_url?: string;
  public_id?: string;
  width?: number;
  height?: number;
}

export interface AuthState {
  images: Image[];
  loading: boolean;
  pageSize: number;
  page: number;
  totalItems: number;
  updateImageData: Image | null;
  message: string | null;
  error: boolean;
}

const initialState: AuthState = {
  images: [],
  loading: false,
  message: null,
  error: false,
  pageSize: 10,
  page: 1,
  totalItems: 0,
  updateImageData: null,
};

const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    setPage(state, action) {
      state.page = action.payload;
    },
    removeImage(state, action) {
      state.images = state.images.filter((image) => image.id !== action.payload);
    },
    setUpdateImageData(state, action) {
      state.updateImageData = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      // uploadImages
      .addCase(uploadImages.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadImages.fulfilled, (state) => {
        state.loading = false;
        NotificationController.success('Images uploaded');
      })
      .addCase(uploadImages.rejected, (state, action) => {
        state.loading = false;
        NotificationController.error(action.payload as string);
      })
      // get all images
      .addCase(getAllImages.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllImages.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload.photos);
        state.images = action.payload.photos.map((type: string) => type as unknown as Image);
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getAllImages.rejected, (state) => {
        state.loading = false;
        NotificationController.error('Event Loading Failed');
      })
      // Delete Image
      .addCase(deleteImage.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteImage.fulfilled, (state) => {
        state.loading = false;
        NotificationController.success('Image Deleted');
      })
      .addCase(deleteImage.rejected, (state, action) => {
        state.loading = false;
        NotificationController.error(action.payload as string);
      });
  },
});

export const { setPage, removeImage, setUpdateImageData } = gallerySlice.actions;

export default gallerySlice.reducer;

export const uploadImages = createAsyncThunk(
  'gallery/upload',
  async (data: { formData: FormData; category_id: number }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/web/admin/photos/upload/${data.category_id}`, data.formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      const errorMessage = handleAxiosError(error);
      return rejectWithValue(errorMessage);
    }
  },
);

export const getAllImages = createAsyncThunk(
  'gallery/get-images',
  async ({ page, page_Size }: { page: number; page_Size: number }) => {
    try {
      const response = await axiosInstance.get(`/web/admin/photos/get-all-images?page=${page}&page_Size=${page_Size}`);
      return response.data;
    } catch (error) {
      const errorMessage = handleAxiosError(error);
      throw new Error(errorMessage);
    }
  },
);

export const deleteImage = createAsyncThunk(
  'gallery/delete-image',
  async (data: { public_id: string; image_id: string }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/web/admin/photos/delete`, data);
      return response.data;
    } catch (error) {
      const errorMessage = handleAxiosError(error);
      return rejectWithValue(errorMessage);
    }
  },
);

export const updateImage = createAsyncThunk(
  'gallery/upload',
  async (
    {
      formData,
      category_id,
      image_id,
      public_id,
    }: { formData: FormData; category_id: number; image_id: string; public_id: string },
    { rejectWithValue },
  ) => {
    try {
      const response = await axiosInstance.post(
        `/web/admin/photos/update?category_id=${category_id}&image_id=${image_id}&public_id=${public_id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return response.data;
    } catch (error) {
      const errorMessage = handleAxiosError(error);
      return rejectWithValue(errorMessage);
    }
  },
);
