import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import Flyer from '../../components/cards/Flyer/Flyer';
import imagepath from '../../assets/images/contact-us-flyer.png';
import TalkWithUs from '../../components/sections/TalkWithUs/TalkWithUs';
import GoogleMaps from '../../components/sections/GoogleMaps/GoogleMaps';

function ContactUs() {
  return (
    <div>
      <div>
        <Header />
        <Flyer primaryText="Contact" secondarytext="Us" url={imagepath} />
        <TalkWithUs />
        <GoogleMaps />
        <Footer />
      </div>
    </div>
  );
}

export default ContactUs;
