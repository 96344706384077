import React from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import TeamsSection from './Sections/TeamsSection';
import CompanyTrust from './Sections/ComapanyTrust';
import Flyer from '../../components/cards/Flyer/Flyer';
import backgroundPhoto from '../../assets/images/two-engineers.png';
import BestService from './Sections/BestService';

function AboutUs() {
  return (
    <div>
      <div>
        <Header />
        <Flyer primaryText="About" secondarytext="Us" url={backgroundPhoto} />
        <CompanyTrust />
        <BestService />
        <TeamsSection />
        <Footer />
      </div>
    </div>
  );
}

export default AboutUs;
