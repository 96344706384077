import React from 'react';
import mainTheme from '../../theme/theme';
import facebook from '../../assets/icons/Facebook.svg';
import instagram from '../../assets/icons/Instagram.svg';
import twitter from '../../assets/icons/Twitter.svg';
import medium from '../../assets/icons/medium.svg';
import linkedin from '../../assets/icons/linkedin.svg';
import logo from '../../assets/icons/company-logo.svg';
import { useNavigate } from 'react-router-dom';
import './Footer.css';
function Footer() {
  const navigate = useNavigate();

  const columnItemStyles = {
    fontSize: '15px',
    fontWeight: mainTheme.fontWeight.regular,
    color: mainTheme.colors.fontSecondary,
  };

  const h3Styles = {
    fontSize: '18px',
    color: mainTheme.colors.fontSecondary,
    fontWeight: mainTheme.fontWeight.medium,
  };

  const linkStyles = {
    textDecoration: 'none',
    color: mainTheme.colors.fontSecondary,
  };

  const imgStyles = {
    padding: '5px',
  };

  return (
    <>
      <div
        style={{
          backgroundColor: mainTheme.colors.primary,
          fontFamily: mainTheme.typography.mainFont,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px',
          }}
        >
          <div>
            <img src={logo} />
          </div>
          <div
          className='quote'
            style={{
              color: mainTheme.colors.fontSecondary,
              fontWeight: mainTheme.fontWeight.regular,
              fontSize: '20px',
              flex: '1',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginRight: '65px',
            }}
          >
            <div
              style={{
                color: mainTheme.colors.fontSecondary,
                padding: '10px',
                cursor: 'pointer',
              }}
              onClick={() => navigate('/')}
            >
              Get a quote
            </div>
          </div>
        </div>

        <div
          className="footer-columns"
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent:'space-between',
            padding: '0 90px 20px 30px',
          }}
        >
          <div className="about-us footer-section" style={{ width: '400px', textAlign: 'justify'}}>
            <h3 style={h3Styles}>About us</h3>
            <p style={columnItemStyles}>
              We are one stop engineering powerhouse based in Perth, providing engineering services to Mining, Oil and
              Gas industries. Our dedication to quality shines through delivering products that are nothing short of
              exceptional
            </p>
          </div>
          <div className="office footer-section" style={{ width: '280px'}}>
            <h3 style={h3Styles}>Office (Location)</h3>
            <p style={columnItemStyles}>3A Alloa Rd, Maddington WA 6109</p>
          </div>

          <div className="contact footer-section" style={{ width: '297px'}}>
            <h3 style={h3Styles}>Contact</h3>
            <p style={columnItemStyles}>
              Email - sales@jbengineering.com.au<br></br>
              Call Us - 0469 824 411
            </p>
          </div>
          <div className="links footer-section" style={{ width: '110px'}}>
            <h3 style={h3Styles}>Links</h3>
            <div style={columnItemStyles}>
              <a href="/" style={linkStyles}>
                Home
              </a>
              <br></br>
              <a href="about" style={linkStyles}>
                About us
              </a>
              <br></br>
              <a href="services" style={linkStyles}>
                Services
              </a>
              <br></br>
              <a href="gallery" style={linkStyles}>
                Gallery
              </a>
              <br></br>
              <a href="contact" style={linkStyles}>
                Contact us
              </a>
            </div>
          </div>
        </div>
        <div
          className="footer-bottom"
          style={{
            display: 'flex',
            marginLeft: '20px',
            justifyContent: 'center'
          }}
        >
          <div style={imgStyles}>
            <img src={facebook} />
          </div>
          <div style={imgStyles}>
            <img src={instagram} />
          </div>
          <div style={imgStyles}>
            <img src={twitter} />
          </div>
          <div style={imgStyles}>
            <img src={medium} />
          </div>
          <div style={imgStyles}>
            <img src={linkedin} />
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
