import React from 'react';
import './Flyer.css';
import mainTheme from '../../../theme/theme';

interface GradientComponentProps {
  url: string;
  primaryText: string;
  secondarytext: string;
}

const GradientComponent: React.FC<GradientComponentProps> = ({ url, primaryText, secondarytext }) => {
  const headingStyle = {
    fontSize: '46px',
    fontWeight: mainTheme.fontWeight.semiBold,
    marginBottom: '0',
    color: mainTheme.colors.fontPrimary,
  };

  const backgroundStyles = {
    background: `linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)), url(${url})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <div className="gradient-container" style={backgroundStyles}>
      <div className="gradient-content">
        <h1 style={headingStyle}>
          {primaryText} <span style={{ color: mainTheme.colors.primary }}>{secondarytext}</span>
        </h1>
      </div>
    </div>
  );
};

export default GradientComponent;
