import React, { useState } from 'react';
import { Grid, AppBar, Toolbar, Typography, IconButton, ListItem } from '@mui/material';
// import { ReactComponent as Logo } from '../../assets/icons/Button (1).svg';
import { ReactComponent as MenuIcon1 } from '../../assets/icons/Button (1).svg';
import { ReactComponent as MenuIcon2 } from '../../assets/icons/Button (2).svg';
import { ReactComponent as MenuIcon3 } from '../../assets/icons/Button (3).svg';
import { ReactComponent as MenuIcon4 } from '../../assets/icons/Button (4).svg';
import './Dashboard.css';
import logo from '../../assets/icons/company-logo.svg';
import logout from '../../assets/icons/logout.svg';
import galleryLogo from '../../assets/icons/gallery.svg';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../redux/store/store';
import { logoutClient } from '../../redux/features/admin/auth/authSlice';
import ViewImages from '../AdminGallery/ViewImages';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import mainTheme from '../../theme/theme';

const Layout = () => {
  const [selectedItem, setSelectedItem] = useState<string | null>('view-gallery');

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleItemClick = (item: string) => {
    setSelectedItem(item === selectedItem ? null : item);
  };

  const handleLogout = async () => {
    await dispatch(logoutClient());
    navigate('/admin/login');
  };

  let componentToRender;

  switch (selectedItem) {
    case 'view-gallery':
      componentToRender = <ViewImages />;
      break;
    default:
      componentToRender = <ViewImages />;
  }

  const logoContainer = {
    flex: '0 0 auto',
  };

  const logoStyles = {
    width: '80px',
  };

  const styleObj = {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
    '&:visited': {
      color: 'transparent',
    },
  };

  return (
    <Grid container spacing={0} style={{ height: '100vh' }}>
      <Grid item xs={2}>
        <div
          style={{
            display: 'flex',
            backgroundColor: '#FFFFFF',
            color: 'black',
            padding: '10px',
            zIndex: 1000,
            height: '100vh',
            borderRight: '1px solid grey',
          }}
        >
          <div className="flex-container">
            <div className="top-items">
              <div className="logoContainer">
                <a href="/">
                  <div style={logoContainer}>
                    <img src={logo} alt="Logo" style={logoStyles} />
                  </div>
                </a>
              </div>
              <ListItem style={{ display: 'flex', alignItems: 'center' }}>
                <PrimaryButton
                  style={{
                    color: selectedItem === 'view-gallery' ? mainTheme.colors.fontSecondary : 'black',
                    backgroundColor:
                      selectedItem === 'view-gallery' ? mainTheme.colors.primary : mainTheme.colors.fontSecondary,
                    height: '40px',
                    width: '180px',
                    fontSize: '15px',
                  }}
                  onClick={() => handleItemClick('view-gallery')}
                >
                  {selectedItem === 'view-gallery' && <img src={galleryLogo} className="gallery-logo" alt="" />}
                  View Gallery
                </PrimaryButton>
              </ListItem>
            </div>
            <div className="bottom-items" style={{ display: 'flex', justifyContent: 'center' }}>
              <IconButton
                onClick={handleLogout}
                style={{ fontSize: '15px', marginBottom: '100px', padding: '20px', borderRadius: '10px' }}
              >
                <img src={logout} className="gallery-logo" alt="" />
                Logout
              </IconButton>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={10}>
        <AppBar
          position="static"
          style={{ backgroundColor: '#FFFFFF', borderBottom: '1px solid grey', boxShadow: 'none' }}
        >
          <Toolbar>
            <div className="navBarStart item-lef">
              <IconButton sx={styleObj} edge="start">
                <MenuIcon1 />
              </IconButton>
              <IconButton sx={styleObj} edge="start">
                <MenuIcon3 />
              </IconButton>

              <Typography variant="h6" style={{ flexGrow: 1, color: 'black', fontWeight: 'lighter', fontSize: '15px' }}>
                Dashboard
              </Typography>
            </div>

            <div className="navBarEnd item-right">
              <IconButton color="inherit">
                <MenuIcon2 />
              </IconButton>

              <IconButton color="inherit">
                <MenuIcon4 />
              </IconButton>
              <IconButton>
                <MenuIcon3 />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <div style={{ padding: '50px' }}>{componentToRender}</div>
      </Grid>
    </Grid>
  );
};

export default Layout;
