interface Theme {
  colors: {
    primary: string;
    fontPrimary: string;
    fontSecondary: string;
    inputField: string;
  };
  typography: {
    mainFont: string;
  };
  spacing: {
    small: string;
    medium: string;
    large: string;
  };
  fontWeight: {
    regular: string;
    medium: string;
    semiBold: string;
  };
}

const mainTheme: Theme = {
  colors: {
    primary: '#FF7420',
    fontPrimary: '#191A19',
    fontSecondary: '#FFFFFF',
    inputField: '#FFF8F4',
  },
  typography: {
    mainFont: '"Poppins", sans-serif',
  },
  spacing: {
    small: '8px',
    medium: '16px',
    large: '24px',
  },
  fontWeight: {
    regular: '400',
    medium: '500',
    semiBold: '600',
  },
};

export default mainTheme;
