import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { AppDispatch, RootState } from '../../redux/store/store';
import mainTheme from '../../theme/theme';
import { updateImage } from '../../redux/features/admin/gallery/gallerySlice';
import FileUploader from '../../components/cards/FileUploader';
import PrimaryButton from '../../components/buttons/PrimaryButton';

interface UpdateImageProps {
  category?: string;
}

const UpdateImage: React.FC<UpdateImageProps> = ({ category }) => {
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [category_id, setCategory] = useState<number>(1);

  const dispatch = useDispatch<AppDispatch>();

  const galleryState = useSelector((state: RootState) => state.admin.gallery);

  const wrapperStyles = {
    backgroundColor: mainTheme.colors.inputField,
    borderColor: mainTheme.colors.primary,
    borderWidth: '1px',
    borderStyle: 'solid',
  };

  const handleAddImage = async () => {
    const formData = new FormData();
    selectedImages.forEach((image) => {
      formData.append(`photo`, image);
    });

    const action = await dispatch(
      updateImage({
        formData,
        category_id,
        image_id: galleryState.updateImageData?.id ?? '',
        public_id: galleryState.updateImageData!.public_id!,
      }),
    );

    const { type } = action;

    if (type.endsWith('/rejected')) {
      window.location.reload();
    } else if (type.endsWith('/fulfilled')) {
      window.location.reload();
    }
  };

  return (
    <div className="inputMain">
      <div className="inputFieldWrapper" style={wrapperStyles}>
        <select onChange={(value) => setCategory(parseInt(value.target.value))} className="inputField browser-default">
          <option value="1" selected={category === '65fdafafbd96244468018a33'}>
            Fabrication
          </option>
          <option value="2" selected={category === '65fe8eda0bfdbacf46a84339'}>
            Machining
          </option>
          <option value="3" selected={category === '65fe8eee0bfdbacf46a8433e'}>
            Abrasive Blasting
          </option>
        </select>
      </div>

      <div className="inputFieldWrapper" style={wrapperStyles}>
        <FileUploader
          buttonText={'Upload Images'}
          onImageChange={(images: File[]): void => {
            setSelectedImages(images);
          }}
        />
      </div>

      <div className="buttonRow">
        <PrimaryButton
          style={{
            color: mainTheme.colors.fontSecondary,
            backgroundColor: mainTheme.colors.primary,
            height: '40px',
            width: '100px',
          }}
          onClick={handleAddImage}
        >
          {galleryState.loading ? <LoadingOverlay /> : 'Update'}
        </PrimaryButton>
      </div>
    </div>
  );
};

const LoadingOverlay = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        backdropFilter: 'blur(5px)',
        zIndex: 9999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress style={{ color: '#FF7420' }} />
    </div>
  );
};

export default UpdateImage;
