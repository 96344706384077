import { useState, ChangeEvent } from 'react';
import mainTheme from '../../../theme/theme';
import FileUploader from '../FileUploader';
import PrimaryButton from '../../buttons/PrimaryButton';
import './QuoteForm.css';
import { AppDispatch } from '../../../redux/store/store';
import { useDispatch } from 'react-redux';
import { sendQuote } from '../../../redux/features/user/contact/contactSlice';

interface QuoteFormData {
  name: string;
  email: string;
  details: string;
}

function QuoteCard() {
  const [selectedImages, setSelectedImages] = useState<File[]>([]);

  const [formData, setFormData] = useState<QuoteFormData>({
    name: '',
    email: '',
    details: '',
  });

  const dispatch = useDispatch<AppDispatch>();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async () => {
    const { name, email, details } = formData;

    const quoteFormData = new FormData();
    selectedImages.forEach((image: File) => {
      quoteFormData.append(`photos`, image);
    });

    quoteFormData.append(`name`, name);
    quoteFormData.append(`email`, email);
    quoteFormData.append(`details`, details);

    console.log(quoteFormData);
    await dispatch(sendQuote({ formData: quoteFormData }));
    window.location.reload();
  };

  return (
    <div
      className="quote-section-container"
      style={{
        fontFamily: mainTheme.typography.mainFont,
        backgroundColor: mainTheme.colors.primary,
      }}
    >
      <h2
        style={{
          fontWeight: mainTheme.fontWeight.medium,
          fontSize: '21px',
          textAlign: 'center',
          color: mainTheme.colors.fontSecondary,
          padding: '20px 0 0 0',
          marginBottom: '-3px',
        }}
      >
        Request a quote
      </h2>
      <p
        style={{
          fontWeight: mainTheme.fontWeight.regular,
          fontSize: '15px',
          textAlign: 'center',
          padding: '0px',
          color: mainTheme.colors.fontSecondary,
        }}
      >
      </p>

      <div style={{ padding: ' 5px 15px' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap'}}>
          <input
            type="text"
            placeholder="Name"
            id="name"
            value={formData.name}
            onChange={handleInputChange}
            style={{
              backgroundColor: mainTheme.colors.inputField,
              borderRadius: '10px',
              padding: '0 0 0 10px',
            }}
            required
          />
          <input
            type="email"
            placeholder="Email"
            id="email"
            value={formData.email}
            onChange={handleInputChange}
            style={{
              backgroundColor: mainTheme.colors.inputField,
              borderRadius: '10px',
              padding: '0 0 0 10px',
            }}
            required
          />
        </div>
        <div>
          <textarea
            placeholder="Additional Details"
            id="details"
            value={formData.details}
            onChange={handleInputChange}
            style={{
              margin: '10px 0px',
              backgroundColor: mainTheme.colors.inputField,
              borderRadius: '10px',
              padding: '10px 0 0 10px',
              height: '150px',
            }}
            rows={3}
            required
          />
        </div>
        <FileUploader
          buttonText={'Upload an attached Files'}
          onImageChange={(images: File[]): void => {
            setSelectedImages(images);
          }}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {' '}
          <PrimaryButton
            style={{
              backgroundColor: mainTheme.colors.fontPrimary,
              fontSize: '18px',
              height: '50px',
              margin: '20px',
              borderRadius: '12px',
              cursor: 'pointer',
            }}
            onClick={handleSubmit}
          >
            Submit
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}

export default QuoteCard;
