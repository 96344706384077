import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance, handleAxiosError } from '../../../../services/api';
import NotificationController from '../../../../services/notification';

interface Image {
  id?: string;
  category_id?: string;
  image_url?: string;
  category_name?: string;
  width?: number;
  height?: number;
}

export interface AuthState {
  images: Image[];
  loading: boolean;
  message: string | null;
  error: boolean;
}

const initialState: AuthState = {
  images: [],
  loading: false,
  message: null,
  error: false,
};

const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // get all images
      .addCase(getAllImages.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllImages.fulfilled, (state, action) => {
        state.loading = false;
        state.images = action.payload.photos.map((type: string) => type as unknown as Image);
      })
      .addCase(getAllImages.rejected, (state) => {
        state.loading = false;
        NotificationController.error('Event Loading Failed');
      });
  },
});

// export const {  } = gallerySlice.actions;

export default gallerySlice.reducer;

export const getAllImages = createAsyncThunk('gallery/get-images', async () => {
  try {
    const response = await axiosInstance.get(`/web/user/photos/get-all-images`);
    console.log(response.data);
    return response.data;
  } catch (error) {
    const errorMessage = handleAxiosError(error);
    throw new Error(errorMessage);
  }
});
