//import PrimaryButton from '../../components/buttons/PrimaryButton';
//import CustomTextField from '../../components/textField/CustomTextField';
import backgroundImage from '../../assets/images/loginBackground.png';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import CustomTextField from '../../components/textField/CustomTextField';
import { checkToken, loginAdmin } from '../../redux/features/admin/auth/authSlice';
import { AppDispatch } from '../../redux/store/store';
import mainTheme from '../../theme/theme';
//import CustomTextField from '@mui/material/TextField';

function AdminPage() {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const handleAuth = async () => {
    const action = await dispatch(checkToken());
    const { type } = action;

    if (type.endsWith('/rejected')) {
      navigate('/admin/login');
    } else if (type.endsWith('/fulfilled')) {
      navigate('/admin/dashboard');
    }
  };

  useEffect(() => {
    handleAuth();
  }, [dispatch]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const [user, setUser] = useState({
    email: '',
    password: '',
  });

  const handleSubmit = async () => {
    const action = await dispatch(loginAdmin(user));
    const { type, payload } = action;

    if (type.endsWith('/fulfilled')) {
      navigate('/admin/dashboard');
    } else {
      console.error(payload);
    }
  };

  
  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: '#000',
        backgroundPosition: 'center 30%',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: mainTheme.typography.mainFont,
      }}
    >
      <div
        className="login"
        style={{
          height: '412px',
          width: '412px',
          backgroundColor: mainTheme.colors.fontPrimary,
          borderRadius: '20px',
          textAlign: 'center',
        }}
      >
        <div
          style={{
            fontWeight: mainTheme.fontWeight.semiBold,
            fontSize: '36px',
            color: mainTheme.colors.fontSecondary,
            padding: '20px 20px  10% 20px',
          }}
        >
          Login
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            padding: '0 40px',
          }}
        >
          <CustomTextField
            onChange={handleChange}
            label="Email"
            name="email"
            additionalProps={{
              borderRadius: '66px',
              labelcolor: mainTheme.colors.fontSecondary,
              color: mainTheme.colors.fontSecondary,
              }}
          />
          <CustomTextField
            onChange={handleChange}
            label="Password"
            name="password"
            additionalProps={{
              borderRadius: '66px',
              labelcolor: mainTheme.colors.fontSecondary,
              color: mainTheme.colors.fontSecondary,
              type: 'password',
            }}
          />
          <PrimaryButton
            onClick={handleSubmit}
            style={{
              color: mainTheme.colors.primary,
              backgroundColor: mainTheme.colors.fontSecondary,
              height: '40px',
              width: '100%',
              borderRadius: '66px',
              marginTop: '20px',
              marginBottom: '-10px',
            }}
          >
            Login
          </PrimaryButton>
          <div
            style={{
              textAlign: 'right',
              color: mainTheme.colors.fontSecondary,
              fontFamily: mainTheme.typography.mainFont,
              fontWeight: mainTheme.fontWeight.medium,
              cursor: 'pointer',
            }}
          >
            Forgot password?
          </div>
        </div>
      </div>
    </div>
  );
}


export default AdminPage;
