// import React from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import Flyer from '../../components/cards/Flyer/Flyer';
import Strip from './Sections/Strip/Strip';
import ServicesGrid from './Sections/ServicesGrid/ServicesGrid';
//import LatestProjects from './Sections/LatestProject/LatestProject';
import imagepath from '../../assets/images/steel-bar-site-construction.png';
import { useNavigate } from 'react-router-dom';

function OurServices() {
  const navigate = useNavigate();

  return (
    <div>
      <div>
        <Header />
        <Flyer primaryText="Our" secondarytext="Services" url={imagepath} />
        <Strip buttonText={'Free quote'} onClick={() => navigate('/')} />
        <ServicesGrid />
        <Footer />
      </div>
    </div>
  );
}

export default OurServices;
