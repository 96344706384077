// GoogleMaps.js
import React from 'react';

const containerStyle = {
  height: '412px',
};

const frameStyles = {
  width: '100%',
  height: '100%',
};

const GoogleMaps = () => {
  return (
    <div style={containerStyle}>
      <iframe
        style={frameStyles}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3381.935575458435!2d115.9854281!3d-32.04393519999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2a32bff5eb1da2fb%3A0x5b02996191313cbd!2sJ%20%26%20B%20Engineering!5e0!3m2!1sen!2slk!4v1710397531935!5m2!1sen!2slk"
        loading="lazy"
      ></iframe>
    </div>
  );
};

export default GoogleMaps;
