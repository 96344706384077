import { useEffect, useState} from 'react';
import { Gallery } from 'react-grid-gallery';
import bgCircle from '../../../../assets/images/bg.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Image } from 'react-grid-gallery';
import { AppDispatch, RootState } from '../../../../redux/store/store';
import { getAllImages } from '../../../../redux/features/user/gallery/gallerySlice';
import mainTheme from '../../../../theme/theme';
import CustomButton from '../../../../components/buttons/CustomButton';

function GalleryDiv() {
  const dispatch = useDispatch<AppDispatch>();

  const [selectedGallery, setSelectedGallery] = useState<string>('all');
  const galleryState = useSelector((state: RootState) => state.user.gallery);
  
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<Image | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  // TODO
  const generateImagesWithCustomOverlay = (images: Image[]): Image[] => {
    return images.map((image) => ({
      ...image,
      customOverlay: (
        <div className="custom-overlay__caption">
          <div>{image.caption}</div>
          {image.tags &&
            image.tags.map((t, index) => (
              <div key={index} className="custom-overlay__tag">
                {t.title}
              </div>
            ))}
        </div>
      ),
    }));
  };

  // const aspectRatio = image.height / image.width;

  // // Set target width and height
  // const targetWidth = 265; // or any other target width
  // const targetHeight = targetWidth * aspectRatio;

  const allImages = generateImagesWithCustomOverlay(
    galleryState.images.map((image) => ({
      src: image.image_url ?? '',
      width: image.width!,
      height: image.height!,
    })),
  );

  const fabricationImages = generateImagesWithCustomOverlay(
    galleryState.images
      .filter((image) => image.category_name === 'Fabrication')
      .map((image) => ({
        src: image.image_url ?? '',
        width: image.width!,
        height: image.height!,
      })),
  );

  const machiningImages = generateImagesWithCustomOverlay(
    galleryState.images
      .filter((image) => image.category_name === 'Machining')
      .map((image) => ({
        src: image.image_url ?? '',
        width: image.width!,
        height: image.height!,
      })),
  );

  const abrasiveBlastingImages = generateImagesWithCustomOverlay(
    galleryState.images
      .filter((image) => image.category_name === 'Abrasive Blasting')
      .map((image) => ({
        src: image.image_url ?? '',
        width: image.width!,
        height: image.height!,
      })),
  );

  const handleGallerySelect = (gallery: string) => {
    setSelectedGallery(gallery);
  };

  const handleImageClick = (index: number, image: Image) => {
    setCurrentImage(image);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setCurrentImage(null);
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % allImages.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + allImages.length) % allImages.length);
    
  };

  const loadData = async () => {
    await dispatch(getAllImages());
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div
      style={{
        fontFamily: mainTheme.typography.mainFont,
        position: 'relative',
        padding: '50px',
      }}
    >
      <img
        src={bgCircle}
        alt="Background Circle"
        style={{
          zIndex: '-1',
          position: 'absolute',
          height: '280px',
          top: -80,
          right: 0,
          transform: 'rotate(180deg)',
        }}
      />
      <h2
        style={{
          fontSize: '28px',
          fontWeight: mainTheme.fontWeight.semiBold,
          textAlign: 'center',
          textTransform: 'capitalize',
          marginTop: '-0px',
        }}
      >
        explore <span style={{ color: mainTheme.colors.primary }}>our portfolio</span>
      </h2>

      <p /*style={{
          textAlign: 'center',
          fontWeight: mainTheme.fontWeight.regular,
          fontSize: '14px',
          marginBottom: '30px',
        }}*/
      >
        
      </p>
      <br />

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '30px',
          marginBottom: '40px',
          flexWrap: 'wrap',
        }}
      >
        <CustomButton onClick={() => handleGallerySelect('all')} selected={selectedGallery === 'all'}>
          All Photos
        </CustomButton>

        <CustomButton onClick={() => handleGallerySelect('maching')} selected={selectedGallery === 'maching'}>
          Machining
        </CustomButton>

        <CustomButton onClick={() => handleGallerySelect('fabrication')} selected={selectedGallery === 'fabrication'}>
          Fabrication
        </CustomButton>

        <CustomButton
          onClick={() => handleGallerySelect('surfaceTreatment')}
          selected={selectedGallery === 'surfaceTreatment'}
        >
          Abrasive Blasting
        </CustomButton>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '60px'}}>
        {selectedGallery === 'all' && (
          <div style={{ width: '90%' }}>
            <Gallery onClick={(index: number, image: Image) => handleImageClick(index, image)} margin={9} images={allImages as Image[]} enableImageSelection={false} />
          </div>
        )}

        {selectedGallery === 'maching' && (
          <div style={{ width: '90%' }}>
            <Gallery onClick={(index: number, image: Image) => handleImageClick(index, image)} margin={9} images={machiningImages as Image[]} enableImageSelection={false} />
          </div>
        )}

        {selectedGallery === 'fabrication' && (
          <div style={{ width: '90%' }}>
            <Gallery onClick={(index: number, image: Image) => handleImageClick(index, image)} margin={9} images={fabricationImages as Image[]} enableImageSelection={false} />
          </div>
        )}

        {selectedGallery === 'surfaceTreatment' && (
          <div style={{ width: '90%' }}>
            <Gallery onClick={(index: number, image: Image)=> handleImageClick(index, image)} margin={9} images={abrasiveBlastingImages as Image[]} enableImageSelection={false} />
          </div>
        )}

        {isOpen && (
          <div style={modalStyles.overlay}>
            <button onClick={closeModal} style={modalStyles.closeButton}>X</button>
            <div style={modalStyles.content}>
                <button onClick={prevImage} style={modalStyles.ArrowBack}><svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="#2d3436"><path d="M400-80 0-480l400-400 71 71-329 329 329 329-71 71Z"/></svg></button>
                {currentImage && <img src={allImages[currentIndex].src} alt={`images ${currentIndex + 1}`} style={modalStyles.image} />}
                {/*currentImage && <img src={currentImage.src} alt='Modal' style={modalStyles.image}/>*/}
                <button onClick={nextImage} style={modalStyles.ArrowForward}><svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="#2d3436"><path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z"/></svg></button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  content: {
    position: 'relative',
    maxWidth: '80%',
    maxHeight: '80%',
    overflow: 'auto',
  },
  closeButton: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    background: 'rgba(99, 110, 114,1.0)',
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    fontSize: '16px',
    cursor: 'pointer',
  },
  ArrowForward: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    background: 'rgba(178, 190, 195,0.4)',
    border: 'none',
    borderRadius: '50%',
    width: '45px',
    height: '45px',
    cursor: 'pointer',
  },
  ArrowBack: {
    position: 'absolute',
    top: '50%',
    left: '10px',
    background: 'rgba(178, 190, 195,0.4)',
    border: 'none',
    borderRadius: '50%',
    width: '45px',
    height: '45px',
    cursor: 'pointer',
  },
  image: {
    maxWidth: '100%',
    height: '40rem',
  },
} as const;

export default GalleryDiv;
