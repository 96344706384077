import React, { useEffect, useRef } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css';
import './carouselStyles.css';
import mainTheme from '../../theme/theme';
import person from '../../assets/images/person.png';
import backArrow from '../../assets/images/back-arrow.png';
import frontArrow from '../../assets/images/front-arrow.png';

interface Testimonial {
  imgSrc: string;
  text: string;
}

const testimonials: Testimonial[] = [
  {
    imgSrc: person,
    text: 'J&B Engineering commitment to delivering high quality work consistently sets a benchmark in the industry. We truly appreciate the standard of excellence.'
  },
  {
    imgSrc: person,
    text: 'J&B Engineering\'s punctuality in meeting deadlines is commendable and greatly appreciated. Your reliability ensures seamless operations and reinforces our trust in your services.'
  },
];

const Carousel: React.FC = () => {
  const carouselRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const carouselInstance = M.Carousel.init(carouselRef.current!, { numVisible: 1 });

    return () => {
      if (carouselInstance) {
        carouselInstance.destroy();
      }
    };
  }, []);

  const navigateCarousel = () => {
    const carouselInstance = M.Carousel.getInstance(carouselRef.current!);
    if (carouselInstance) {
      carouselInstance.next();
    }
  };

  return (
    <div className="carousel" style={{ height: '300px'}} ref={carouselRef}>
      {testimonials.map((testimonial, index) => (
        <a key={index} className="carousel-item">
          <div className="testi" style={{ color: mainTheme.colors.fontPrimary }}>
            <div style={{ display: 'flex',  justifyContent: 'space-between', alignItems: 'center'}}>
              <img
                src={backArrow}
                style={{ width: '14px', height: '20px', cursor: 'pointer',marginRight: '20px'}}
                onClick={navigateCarousel}
              />
              <p
              style={{
                // width: '800px',
                fontWeight: mainTheme.fontWeight.regular,
                padding: '10px',
              }}
              >
              {testimonial.text}
            </p>
              <img
                src={frontArrow}
                style={{ width: '14px', height: '20px', cursor: 'pointer',marginLeft: '20px'}}
                onClick={navigateCarousel}
              />
            </div>
          </div>
        </a>
      ))}
    </div>
  );
};

export default Carousel;