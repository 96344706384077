import mainTheme from '../../../theme/theme';
import person1 from '../../../assets/images/person1.jpg';
import person2 from '../../../assets/images/person2.jpg';
import bgCircle from '../../../assets/images/bg.svg';
//import { useState } from 'react';

function TeamsSection() {
  const primaryColor = mainTheme.colors.primary;

  return (
    <div
      style={{
        padding: '50px',
        backgroundColor: `rgba(${parseInt(primaryColor.slice(1, 3), 16)}, ${parseInt(primaryColor.slice(3, 5), 16)}, ${parseInt(primaryColor.slice(5, 7), 16)}, 0.05)`,
      }}
    >
      <img 
        src={bgCircle} 
        alt="Background Circle"
        style={{ 
          zIndex: '-1',
          position: 'absolute',
          height: '250px',
          top: 1190,
          left: -20,
          transform: 'rotate(-310deg)',
         }} />
        <img 
        src={bgCircle} 
        alt="Background Circle"
        style={{ 
          zIndex: '-1',
          position: 'absolute',
          height: '280px',
          bottom: -1173,
          right: 0,
          transform: 'rotate(180deg)',
         }} />
      <div
        style={{
          textAlign: 'center',
          fontFamily: mainTheme.typography.mainFont,
        }}
      >
        <h1 style={{ fontSize: '41px', fontWeight: mainTheme.fontWeight.semiBold }}>
          <span style={{ color: mainTheme.colors.primary }}>Our </span>
          <span style={{ color: mainTheme.colors.fontPrimary }}>Team</span>
        </h1>
        <h4
          style={{
            color: mainTheme.colors.fontPrimary,
            fontSize: '18px',
            fontWeight: mainTheme.fontWeight.medium,
          }}
        >
          In every opportunity we are learning from each other <br />
          to give you the Best.
        </h4>
       
        <div style={{ display: 'flex', gap: '40px', justifyContent: 'center', margin: '50px',flexWrap: 'wrap' }}> 
        <div>
          <img src={person1}
              alt="Director"
              style={{
                position: 'relative',
                width: '180px',
                height: '230px',
                borderRadius: '5px',
                overflow: 'hidden',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }} />
              <br />
            <span style={{ fontSize: '17.5px',fontWeight: mainTheme.fontWeight.medium, }}>Parminderpal Singh</span><br />
            <span style={{ fontSize: '15px',fontWeight: mainTheme.fontWeight.medium,}}>DIRECTOR</span><br />
            <span style={{ fontSize: '13px',fontWeight: mainTheme.fontWeight.medium, }}>0469 824 411</span><br />
            <span style={{ fontSize: '13px',fontWeight: mainTheme.fontWeight.medium, }}>sales@jbengineering.com.au</span><br />
          </div>
          <div>
            <img src={person2}
              alt="Operations Manager"
              style={{
                position: 'relative',
                width: '180px',
                height: '230px',
                borderRadius: '5px',
                overflow: 'hidden',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }} />
              <br />
            <span style={{ fontSize: '17.5px',color: mainTheme.colors.fontPrimary, fontWeight: mainTheme.fontWeight.medium, }}>Harkamaldeep Singh</span><br />
            <span style={{ fontSize: '15px',fontWeight: mainTheme.fontWeight.medium, }}>OPERATIONS MANAGER</span><br />
            <span style={{ fontSize: '13px',fontWeight: mainTheme.fontWeight.medium, }}>0447 788 799</span><br />
            <span style={{ fontSize: '13px',fontWeight: mainTheme.fontWeight.medium, }}>operations@jbengineering.com.au</span><br />
          </div>
        </div>
      </div>
    </div>  
  );
}
export default TeamsSection;        
