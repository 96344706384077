import './BackGroundCircle.css';
import svg from '../../../assets/images/bg.svg';

interface SvgContainerProps {
  top: number;
  right: number;
  rotate: number;
  left: number;
}

const SvgContainer: React.FC<SvgContainerProps> = ({ top, right, rotate, left }) => {
  // Determine which CSS property to use for horizontal positioning
  const horizontalPosition = left === 0 ? 'right' : 'left';

  // Define container styles
  const containerStyles = {
    top: `${top}%`,
    [horizontalPosition]: `${left !== 0 ? left : right}%`, // Use left if not 0, otherwise use right
    transform: `rotate(${rotate}deg)`,
  };

  return (
    <div className="parent-div" style={containerStyles}>
      <div className="svg-container">
        <img src={svg} alt="" />
      </div>
    </div>
  );
};

export default SvgContainer;
