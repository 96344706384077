import { ChangeEvent, useState } from 'react';
import './TalkWithUs.css';
import mainTheme from '../../../theme/theme';
import email from '../../../assets/icons/mail.svg';
import phone from '../../../assets/icons/phone.svg';
import location from '../../../assets/icons/location.svg';
import PrimaryButton from '../../buttons/PrimaryButton';
import SvgContainer from '../BackgroundCircle/BackGroundCircle';
import { AppDispatch } from '../../../redux/store/store';
import { useDispatch } from 'react-redux';
import { sendUserInfo } from '../../../redux/features/user/contact/contactSlice';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    option: 'SandBlasting',
    details: '',
  });

  const dispatch = useDispatch<AppDispatch>();

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    console.log('HHHHHHHHHHHHH');

    await dispatch(sendUserInfo(formData));
    window.location.reload();
  };

  const wrapperStyles = {
    backgroundColor: mainTheme.colors.inputField,
    borderColor: mainTheme.colors.primary,
    borderWidth: '1px', // Set the border size here
    borderStyle: 'solid',
  };

  return (
    <section className="section">
      <SvgContainer top={60} left={0} right={-2} rotate={180} />

      <h1 className="header-tw">
        Let’s talk with <span style={{ color: mainTheme.colors.primary }}>Us</span>
      </h1>

      <div className="container">
        <div className="column leftColumn">
          <h2 className="innerHeader">We'd love to hear from you</h2>
   
          <br />
          <br />
          <div>
            <div className="iconRow">
              <span className="icon">
                <img src={location} alt="" />
              </span>
              <span className="iconText"> Location: 3A Alloa Rd, Maddington WA 6109</span>
            </div>
            <div className="iconRow">
              <span className="icon">
                <img src={phone} alt="" />
              </span>
              <span className="iconText"> Call: 0469 824 411</span>
            </div>
            <div className="iconRow">
              <span className="icon">
                <img src={email} alt="" />
              </span>
              <span className="iconText"> Email: sales@jbengineering.com.au</span>
            </div>
          </div>
        </div>
        <div className="column rightColumn">
          <div className="inputFieldWrapper" style={wrapperStyles}>
            <input
              style={{ backgroundColor: mainTheme.colors.inputField }}
              type="text"
              placeholder="Name"
              id="name"
              className="inputField browser-default"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="inputFieldWrapper" style={wrapperStyles}>
            <input
              style={{ backgroundColor: mainTheme.colors.inputField }}
              type="text"
              placeholder="Email"
              id="emaail"
              className="inputField browser-default"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="inputFieldWrapper" style={wrapperStyles}>
            <select
              className="inputField browser-default"
              name="option"
              value={formData.option}
              onChange={handleSelectChange}
            >
              <option value="SandBlasting">Sand Blasting & Spray Painting</option>
              <option value="Machining">Machining</option>
              <option value="Fabrication">Fabrication</option>
            </select>
          </div>
          <div className="inputFieldWrapper" style={wrapperStyles}>
            <textarea
              placeholder="Additional Details"
              id="details"
              className="textareaField"
              rows={4}
              name="details"
              value={formData.details}
              onChange={handleChange}
            />
          </div>
          <div className="btn-wrapper">
            <PrimaryButton
              style={{
                color: mainTheme.colors.fontSecondary,
                backgroundColor: mainTheme.colors.primary,
                height: '50px',
                width: '150px',
                cursor: 'pointer',
                justifyContent: 'center'
              }}
              onClick={handleSubmit}
            >
              Submit
            </PrimaryButton>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
