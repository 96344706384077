import React, { ChangeEvent, ReactNode } from 'react';
import TextField from '@mui/material/TextField';
import mainTheme from '../../theme/theme';
//import { Hidden } from '@mui/material';

// Define additional properties specific to your SVG icon
// interface IconProps {
//   passwordIcon?: boolean;
// }

interface PrimaryFieldProps {
  width?: string;
  className?: string;
  label?: string;
  value?: string;
  name?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  additionalProps?: {
    borderRadius?: string;
    backgroundColor?: string;
    color?: string | undefined;
    labelcolor?: string | undefined;
    type?: React.HTMLInputTypeAttribute | undefined;
    multiline?: boolean;
    rows?: number;
    height?: string;
  };
  icon?: ReactNode;
}

const CustomTextField: React.FC<PrimaryFieldProps> = ({
  width,
  className,
  label,
  name,
  value,
  onChange,
  additionalProps,
  icon,
}) => {
  const {
    borderRadius,
    backgroundColor,
    color,
    labelcolor,
    type,
    multiline = false,
    rows = 4,
    height = 'auto',
  } = additionalProps || {};

  const mainColor = mainTheme.colors.fontSecondary;

  return (
    <>
      <TextField
        name={name}
        value={value}
        label={label}
        type={type}
        rows={rows}
        multiline={multiline}
        InputProps={{
          startAdornment: icon && <div style={{ marginRight: '8px', color: 'grey', }}>{icon}</div>, // Check if icon prop is provided and render it
          style: {
            color: color || 'black',
            fontFamily: 'League Spartan',
            fontSize: '14px',
            overflow: 'hidden',
          },
          sx: { borderRadius: borderRadius || undefined,
           },
        }}
        sx={{
          background: backgroundColor || undefined,
          borderRadius: borderRadius || undefined,
          width: width,
          height: height,
          '& label': {
            color: 'red',
          },
          '& label.Mui-focused': {
            color: 'black',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: mainColor,
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: mainColor,
            },
            '&:hover fieldset': {
              borderColor: mainColor,
            },
            '&.Mui-focused fieldset': {
              borderColor: mainColor,
            },
          },
          '&:focus': {
            borderColor: 'black',
          },
        }}
        InputLabelProps={{
          style: {
            fontWeight: 400,
            fontFamily: 'League Spartan',
            fontSize: '14px',
            margin: '3px 0px 0px 8px ',
            color: labelcolor || 'black',          },
        }}
        className={className}
        onChange={onChange}
      />
    </>
  );
};

export default CustomTextField;
