import React, { CSSProperties } from 'react';
import mainTheme from '../../theme/theme';
import PropTypes from 'prop-types';

interface CustomButtonProps {
  children: React.ReactNode;
  style?: CSSProperties;
  onClick?: () => void;
  selected: boolean;
}

function CustomButton({ children, onClick, selected, style }: CustomButtonProps) {
  const defaultStyles: React.CSSProperties = {
    backgroundColor: selected ? mainTheme.colors.primary : mainTheme.colors.fontSecondary,
    color: selected ? mainTheme.colors.fontSecondary : 'rgba(0, 0, 0, 0.5)',
    textTransform: selected ? 'capitalize' : 'uppercase',
    borderRadius: '8px',
    padding: '10px 20px',
    fontSize: '18px',
    fontFamily: mainTheme.typography.mainFont,
    fontWeight: mainTheme.fontWeight.medium,
    border: 'none',
    cursor: 'pointer',
  };

  const buttonStyles = {
    ...defaultStyles,
    ...style,
  };

  return (
    <button style={buttonStyles} onClick={onClick}>
      {children}
    </button>
  );
}

CustomButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

export default CustomButton;
