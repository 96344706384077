import React from 'react';
import mainTheme from '../../../theme/theme';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import timerSvg from '../../../assets/icons/timer.svg';
import shieldSvg from '../../../assets/icons/shield.svg';
import gearPng from '../../../assets/icons/gear.png';
import peopleSvg from '../../../assets/icons/people.svg';
import qualitySvg from '../../../assets/icons/quality.svg';
import checkSvg from '../../../assets/icons/check.svg';
import './BestService.css';

function BestService() {
  const navigate = useNavigate();

  return (
    <div
      className="bs-container"
      style={{
        color: mainTheme.colors.fontPrimary,
        fontFamily: mainTheme.typography.mainFont,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div className="main-div1" style={{ padding: '30px 120px 30px '}}>
        <h1>
          Our Best <span style={{ color: mainTheme.colors.primary }}>Services</span>
        </h1>
        <h1 style={styles.heading}> & Solutions</h1>
        <br />
        <br />
        <br />
        <div className="btn-container">
          <PrimaryButton onClick={() => navigate('/contact')}>call us now</PrimaryButton>
        </div>
      </div>
      <div className="main-div2" style={{ padding: '20px'}}>
        <div className="timer-grid">
          <TimerComponent text="24 Hrs Service" svgSrc={timerSvg} />
          <TimerComponent text="100% Trust" svgSrc={shieldSvg} />
          <TimerComponent text="Professional" svgSrc={gearPng} />
        </div>
        <div className="timer-grid">
          <TimerComponent text="Certified" svgSrc={checkSvg} />
          <TimerComponent text="Cooperative" svgSrc={peopleSvg} />
          <TimerComponent text="Professional" svgSrc={qualitySvg} />
        </div>
      </div>
    </div>
  );
}

const styles = {
  heading: {
    fontWeight: mainTheme.fontWeight.semiBold,
  },
};

interface TimerComponentProps {
  svgSrc: string;
  text: string;
}

const TimerComponent: React.FC<TimerComponentProps> = ({ svgSrc, text }) => {
  return (
    <div className="timer-box">
      <img src={svgSrc} alt={text} />
      <div
        className="timer-text"
        style={{ color: mainTheme.colors.primary, fontWeight: mainTheme.fontWeight.semiBold }}
      >
        {text}
      </div>
    </div>
  );
};

export default BestService;
