import React from 'react';
import './ServicesGrid.css';
import img1 from '../../../../assets/images/latest-project-card-4.jpeg';
import img2 from '../../../../assets/images/latest-project-card-5.png';
import img3 from '../../../../assets/images/latest-project-card-6.png';
import img4 from '../../../../assets/images/latest-project-card-10.png';
import img5 from '../../../../assets/images/latest-project-card-1.jpeg';
import mainTheme from '../../../../theme/theme';
import SvgContainer from '../../../../components/sections/BackgroundCircle/BackGroundCircle';

const ServicesGrid = () => {
  return (
    <div className="grid-container">
      <SvgContainer top={35} right={0} rotate={0} left={-5} />

      <div className="grid-box image-box" style={{ backgroundImage: `url(${img5})` }}></div>
      <div className="grid-box paragraph-box" id="content2">
          <div className="vertical-ruler"></div>
          <div className="content">
            <h1 style={{ fontSize: '25px', fontWeight: mainTheme.fontWeight.medium }}>Machining</h1>
            <p style={{ fontSize: '18px' }}>
              We offer machining services to meet your manufacturing needs with efficiency and quality in every project.
            </p>
          </div>
      </div>
    
      <div className="grid-box image-box" style={{ backgroundImage: `url(${img1})` }}></div>
        <div className="grid-box paragraph-box" id="content1">
          <div className="vertical-ruler"></div>
          <div className="content">
            <h1 style={{ fontSize: '24px', fontWeight: mainTheme.fontWeight.medium }}>ABRASIVE BLASTING & SPRAY PAINTING</h1>
            <p style={{ fontSize: '17.5px' }}>
              We redefine the art of surface preparation through our cutting edge abrasive blasting services. With a
              commitment to excellence we bring you the finest in abrasive blasting technology.
            </p>
          </div>
      </div>
        
      <div className="grid-box image-box" style={{ backgroundImage: `url(${img2})` }}></div>
      <div className="grid-box paragraph-box" id="content3">
        <div className="vertical-ruler"></div>
        <div className="content">
          <h1 style={{ fontSize: '25px', fontWeight: mainTheme.fontWeight.medium }}>Fabrication</h1>
          <p style={{ fontSize: '18px' }}>
            We sculpt innovation and ideas through expert fabrication With a blend of craftsmanship and advanced
            technology, we turn raw materials into functional work of art that redefine industries.
          </p>
        </div>
      </div>

      <div className="grid-box image-box" style={{ backgroundImage: `url(${img3})` }}></div>
      <div className="grid-box paragraph-box" id="content4">
        <div className="vertical-ruler"></div>
        <div className="content">
          <h1 style={{ fontSize: '25px', fontWeight: mainTheme.fontWeight.medium }}>Surface Treatment</h1>
          <p style={{ fontSize: '18px' }}>
            Surface treatment is a crucial step in enhancing the appearance, durability, 
            and performance of your products.Our team of experts works closely with you 
            to develop customized surface treatment solutions that meet your specific 
            requirements and project goals.
          </p>
        </div>
      </div>
      <div className="grid-box image-box" style={{ backgroundImage: `url(${img4})`}}></div>
    </div>
  );
};

export default ServicesGrid;
