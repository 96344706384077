// CompanyTrust.js

import React from 'react';
import './CompanyTrust.css';
import mainTheme from '../../../theme/theme';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import exp1 from '../../../assets/images/latest-project-card-4.jpeg';
import exp2 from '../../../assets/images/latest-project-card-1.jpeg';
import exp3 from '../../../assets/images/latest-project-card-10.png';
import exp4 from '../../../assets/images/latest-project-card-6.png';

function CompanyTrust() {
  // return (
  //   <div className="experience-section-container">
  //     <div
  //       className="experience-images-container"
  //       style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridGap: '0px' }}
  //     >
  //       <div
  //         className="experience-image experience-image-1"
  //         style={{ display: 'flex', flexDirection: 'column', gridGap: '10px' }}
  //       >
  //         <img src={exp4} alt="icon" style={{ height: 'auto', width: '233px' }} />
  //         <img src={exp1} alt="icon" style={{ height: 'auto', width: '233px' }} />
  //       </div>
  //       <div
  //         className="experience-image experience-image-2"
  //         style={{ display: 'flex', flexDirection: 'column', gridGap: '15px', height: '100%' }}
  //       >
  //         <img src={exp3} alt="icon" style={{ height: 'auto', width: '279px' }} />
  //         <img src={exp2} alt="icon" style={{ height: '250px', width: '279px' }} />
  //       </div>
  //     </div>

  //     <div className="experience-content">
  //       <h1
  //         style={{
  //           fontWeight: mainTheme.fontWeight.semiBold,
  //           fontFamily: mainTheme.typography.mainFont,
  //           color: mainTheme.colors.fontPrimary,
  //           width: '100%',
  //           margin: '0',
  //         }}
  //         className="title"
  //       >
  //         The <span> Company</span> You can trust
  //       </h1>
  //       <p className="experience-text">
  //       One Stop Engineering powerhouse based in perth. We provide machining, fabrication and surface treatment services to mining, oil and Gas industries.
  //       We source high quality steel wear plates and fasteners (Liner Bolts).
  //       </p>
  //       <p className="experience-text">
  //       Our mission is to provide cutting-edge metal engineering solutions tailored to our client's needs, leveraging our expertise and commitment of excellence.
  //       </p>
  //       <p className="experience-text">
  //       We strive to foster a culture of continuous improvement, environmental stewardship and 
  //       customer satisfaction, while maintaining high standards of integrity and ethical business practices.
  //       </p>
  //       <div className="btn-container">
  //         <PrimaryButton
  //           style={{
  //             color: mainTheme.colors.fontSecondary,
  //             backgroundColor: mainTheme.colors.primary,
  //             marginLeft: '20px',
  //             cursor: 'pointer',
  //             width: '210px',
  //             height: '50px',
  //           }}
  //         >
  //           Get A Free Quote
  //         </PrimaryButton>
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <div className="trust-container">
      <div className="trust-grid" style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gridGap: '0px' }}>
        <div
          className="image-column experience-image-1"
          style={{ display: 'flex', flexDirection: 'column', gridGap: '10px', height: '100%' }}
        >
          <img src={exp4} style={{ height: '188px', width: '233px' }} alt="icon" className="image" />
          <img src={exp1} style={{ height: 'auto', width: '233px' }} alt="icon" className="image" />
        </div>
        <div
          className="image-column experience-image-2"
          style={{ display: 'flex', flexDirection: 'column', gridGap: '10px', height: '100%' }}
        >
          <img src={exp3} style={{ height: '130px', width: '279px' }} alt="icon" className="image" />
          <img src={exp2} style={{ height: '215px', width: '282px' }} alt="icon" className="image" />
        </div>
      </div>
      <div className="content">
        <h1
          style={{
            fontWeight: mainTheme.fontWeight.semiBold,
            fontFamily: mainTheme.typography.mainFont,
            color: mainTheme.colors.fontPrimary,
            width: '100%',
            margin: '0',
          }}
          className="title"
        >
          The <span> Company</span> You can trust
        </h1>
        <p className="paragraph">
          <b>One Stop Engineering powerhouse based in perth. We provide machining, fabrication and surface treatment services to mining, oil and Gas industries.
          We source high quality steel wear plates and fasteners</b>
        </p>
        <p className="paragraph">
          Our mission is to provide cutting-edge metal engineering solutions tailored to our client's needs, leveraging our expertise and commitment of excellence.
        </p>
        <p className="paragraph">
           We strive to foster a culture of continuous improvement, environmental stewardship and 
           customer satisfaction, while maintaining high standards of integrity and ethical business practices.
        </p>
        <div className="btn-container">
          <PrimaryButton
            style={{
              color: mainTheme.colors.fontSecondary,
              backgroundColor: mainTheme.colors.primary,
              marginLeft: '20px',
              cursor: 'pointer',
              width: '210px',
              height: '50px',
            }}
          >
            Get A Free Quote
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}

export default CompanyTrust;
