import { combineReducers } from 'redux';
import authReducer from './auth/authSlice';
import galleryReducer from './gallery/gallerySlice';

const adminReducers = combineReducers({
  auth: authReducer,
  gallery: galleryReducer,
});

export default adminReducers;
