import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/icons/company-logo.svg';
import Button from '../buttons/PrimaryButton';
import './Header.css';
import mainTheme from '../../theme/theme';
import SideHeader from './SideHeader/SideHeader';
import { IoMenu } from 'react-icons/io5';

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(true);

  const handleExpandToggle = () => {
    console.log('expanding', isExpanded);
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="header" style={styles.header}>
      <a href="/" className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </a>
      <p className = "name element">J&B Engineering</p> 

      {!isExpanded ? <SideHeader /> : null}

      <div className="nav-container expanded element">
        <a href="/" className="nav-link" style={styles.link}>
          Home
          {location.pathname === '/' ? <div className="selected-nav"></div> : null}
        </a>
        <a href="about" className="nav-link" style={styles.link}>
          About Us
          {location.pathname === '/about' ? <div className="selected-nav"></div> : null}
        </a>
        <a href="services" className="nav-link" style={styles.link}>
          Services
          {location.pathname === '/services' ? <div className="selected-nav"></div> : null}
        </a>
        <a href="gallery" className="nav-link" style={styles.link}>
          Gallery
          {location.pathname === '/gallery' ? <div className="selected-nav"></div> : null}
        </a>
        <a href="contact" className="nav-link" style={styles.link}>
        <Button
          onclick={() => {
            navigate('/contact');
          }}
          style={{ cursor: 'pointer', marginLeft: '20px', width: '150px', height: '45px' }}
        >
          Contact Us
        </Button>
        </a>
      </div>

      <div className="nav-toggle">
        <IoMenu onClick={handleExpandToggle} />
      </div>
    </div>
  );
}

const styles = {
  link: {
    color: mainTheme.colors.fontPrimary,
    fontFamily: mainTheme.typography.mainFont,
    fontWeight: mainTheme.fontWeight.medium,
  },
  header: {
    backgroundColor: mainTheme.colors.fontSecondary,
  },
};

export default Header;
