import mainTheme from '../../../../theme/theme';
import QuoteCard from '../../../../components/cards/QuoteForm/QuoteForm';
import './QuoteSection.css'; // Import the CSS file for additional styles

// Import images as variables
import checkCircle from '../../../../assets/icons/CheckCircle.svg';
// import quoteImage from '../../../../assets/images/quoteImage.png';

function QuoteSection() {
  return (
    <div
      className="quote-section"
      style={{
        fontFamily: mainTheme.typography.mainFont,
        backgroundColor: mainTheme.colors.fontPrimary,
      }}
    >
      <div className="quote-section-overlay">
        <h1 style={styles.h1} className="quote-section-title">
          Leading way in fabrication and surface treatment
        </h1>
        <div className="checklist">
          <div className="checklist-item">
            <img src={checkCircle} alt="Checkmark" />
            <p style={styles.p} className="checklist-text">
              Professional staff
            </p>
          </div>
          <div className="checklist-item">
            <img src={checkCircle} alt="Checkmark" />
            <p style={styles.p} className="checklist-text">
              100% satisfaction
            </p>
          </div>
          <div className="checklist-item">
            <img src={checkCircle} alt="Checkmark" />
            <p style={styles.p} className="checklist-text">
              Accurate testing
            </p>
          </div>
          <div className="checklist-item">
            <img src={checkCircle} alt="Checkmark" />
            <p style={styles.p} className="checklist-text">
              Transparent pricing
            </p>
          </div>
        </div>
        <QuoteCard />
      </div>
    </div>
  );
}

const styles = {
  h1: {
    fontWeight: mainTheme.fontWeight.medium,
    color: mainTheme.colors.fontSecondary,
  },
  p: {
    color: mainTheme.colors.fontSecondary,
  },
};

export default QuoteSection;
