import React from 'react';
import MilestoneCard from '../../../../components/cards/MilestoneCard';
import mainTheme from '../../../../theme/theme';
import './MilestoneSection.css';
import bgCircle from '../../../../assets/images/bg.svg';

function MilestoneSection() {
  const primaryColor = mainTheme.colors.primary;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        maxWidth: '100%',
        backgroundColor: `rgba(${parseInt(primaryColor.slice(1, 3), 16)}, ${parseInt(primaryColor.slice(3, 5), 16)}, ${parseInt(primaryColor.slice(5, 7), 16)}, 0.05)`,
        padding: '10px 30px',
      }}
    >
      <div className="ml-card-1">
        <MilestoneCard number="8+" para="Years of Experience" contentWidth="98px"></MilestoneCard>
      </div>
      <div className="ml-card-2">
        <MilestoneCard number="400+" para="Project Complete" contentWidth="91px"></MilestoneCard>
      </div>
      <img 
          src={bgCircle} 
          alt="Background Circle"
          style={{ 
            zIndex:'-1',
            position: 'absolute',
            height: '280px',
            right: 0,
            transform: 'rotate(180deg)',
           }} />
    </div>
  );
}

export default MilestoneSection;