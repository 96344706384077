import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import Testimonial from './Sections/Testimonial/Testimonial';
import ExperienceSection from './Sections/ExperienceSection/ExperienceSection';
import FirstSection from './Sections/FirstSection/FirstSection';
import MilestoneSection from './Sections/MilestoneSection/MilestoneSection';
import QuoteSection from './Sections/QuoteSection/QuoteSection';

function HomePage() {
  return (
    <div>
      <Header />
      <FirstSection />
      <MilestoneSection />
      <ExperienceSection />
      <Testimonial />
      <QuoteSection />
      <Footer />
    </div>
  );
}

export default HomePage;
