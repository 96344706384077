import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { axiosInstance, handleAxiosError } from '../../../../services/api';
import NotificationController from '../../../../services/notification';

interface Admin {
  first_name?: string;
  last_name?: string;
  email?: string;
}

export interface AuthState {
  user: Admin | null;
  loading: boolean;
  message: string | null;
  error: boolean;
}

const initialState: AuthState = {
  user: null,
  loading: false,
  message: null,
  error: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      // Login
      .addCase(loginAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        NotificationController.success(action.payload.message);
      })
      .addCase(loginAdmin.rejected, (state, action) => {
        state.loading = false;
        NotificationController.error(action.payload as string);
      })
      // Validate Token
      .addCase(checkToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkToken.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.message;
      })
      .addCase(checkToken.rejected, (state, action) => {
        state.loading = false;
        state.message = action.payload as string;
      });
  },
});

// export const isLoading = (state: AuthState) => state.loading;

export default authSlice.reducer;

export const loginAdmin = createAsyncThunk('admins/login', async (userData: object, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post('/web/admin/auth/login', userData);
    return response.data;
  } catch (error) {
    const errorMessage = handleAxiosError(error);
    return rejectWithValue(errorMessage);
  }
});

export const logoutClient = createAsyncThunk('admins/logout', async () => {
  try {
    const response = await axiosInstance.post('/web/admin/auth/logout');
    return response.data;
  } catch (error) {
    const errorMessage = handleAxiosError(error);
    throw new Error(errorMessage);
  }
});

export const checkToken = createAsyncThunk('auth/checkToken', async () => {
  try {
    const response = await axiosInstance.post('/web/admin/auth/validate-token');
    return response.data;
  } catch (error) {
    throw new Error('Token Invalid or Expired');
  }
});
