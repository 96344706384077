import mainTheme from '../../../../theme/theme';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import backgroundPhoto from '../../../../assets/images/truck.jpg';
import services from '../../../../assets/icons/arrow-right-circle.svg';
import { useNavigate } from 'react-router-dom';
import './FirstSection.css';

function FirstSection() {
  const headingStyle = {
    fontSize: '64px',
    fontWeight: mainTheme.fontWeight.semiBold,
    marginBottom: '0',
    color: mainTheme.colors.fontPrimary,
  };

  const navigate = useNavigate();

  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%), url(${backgroundPhoto})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '750px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        color: mainTheme.colors.fontPrimary,
        paddingLeft: '35px',
        fontFamily: mainTheme.typography.mainFont,
      }}
    >
      <h1 style={headingStyle}>We Prepare</h1>
      <h1 style={{ ...headingStyle, marginTop: '0' }}>
        For The <span style={{ color: mainTheme.colors.primary }}>Future</span>
      </h1>
      <p
        style={{
          fontWeight: mainTheme.fontWeight.medium,
          fontSize: '20px',
          marginBottom: '30px',
        }}
      >
      One Stop engineering powerhouse based in perth. <br/>
      We provide machining, fabrication and surface treatment services  <br/>to mining, oil and Gas industries.
      <br/><b>We source high quality steel wear plates and fasteners.</b>
      </p>
      <div className="button-section">
        <div className="single-button-section">
          <PrimaryButton
            style={{
              fontSize: '16px',
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              width: '180px',
              height: '50px',
            }}
            onClick={() => navigate('/services')}
          >
            Our Services
            <img src={services} alt="Service icon" style={{ marginLeft: '10px' }} />
          </PrimaryButton>
        </div>
        <div className="single-button-section">
          <PrimaryButton
            style={{
              color: mainTheme.colors.fontPrimary,
              backgroundColor: mainTheme.colors.fontSecondary,
              cursor: 'pointer',
              width: '180px',
              height: '50px',
            }}
            onClick={() => navigate('/gallery')}
          >
            View Gallery
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}

export default FirstSection;
