import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import GalleryDiv from './Sections/GalleryPage/GalleryDiv';
import backgroundPhoto from '../../assets/images/gallery-background.png';
import Flyer from '../../components/cards/Flyer/Flyer';

function GalleryPage() {
  return (
    <div>
      <Header />
      <Flyer primaryText="Our" secondarytext="Works" url={backgroundPhoto} />
      <GalleryDiv />
      <Footer />
    </div>
  );
}

export default GalleryPage;
