import { combineReducers } from 'redux';
import adminReducers from '../features/admin';
import userReducers from '../features/user';

const rootReducer = combineReducers({
  admin: adminReducers,
  user: userReducers,
});

export default rootReducer;
