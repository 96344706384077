import React, { useState } from 'react';
import mainTheme from '../../theme/theme';
import uploadIcon from '../../assets/icons/upload.svg';
import folderSvg from '../../assets/icons/folder.svg';

interface FileUploaderProps {
  buttonText: string;
  onImageChange: (images: File[]) => void;
  multiple?: boolean;
}

const FileUploader: React.FC<FileUploaderProps> = ({ buttonText, onImageChange, multiple = false }) => {
  const [hovering, setHovering] = useState(false);
  const [previewImages, setPreviewImages] = useState<File[]>([]);

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setHovering(true);
  };

  const handleDragLeave = () => {
    setHovering(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setHovering(false);
    const files = e.dataTransfer.files;
    const newImages = Array.from(files) as File[];
    setPreviewImages((prevImages) => [...prevImages, ...newImages]);
    onImageChange([...previewImages, ...newImages]);
  };

  const handleClick = () => {
    document.getElementById('file-input')?.click();
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ?? [];
    const newImages = Array.from(files) as File[];
    setPreviewImages((prevImages) => [...prevImages, ...newImages]);
    onImageChange([...previewImages, ...newImages]);
  };

  const handleRemoveImage = (id: string) => {
    const filteredImages = previewImages.filter((image) => image.name !== id);
    setPreviewImages(filteredImages);
    onImageChange(filteredImages);
  };

  return (
    <div>
      <div
        style={{
          margin: '10px 0px',
          border: '2px dashed #ccc',
          borderRadius: '10px',
          padding: '40px 20px',
          textAlign: 'center',
          cursor: 'pointer',
          backgroundColor: hovering ? '#f0f0f0' : mainTheme.colors.inputField,
          overflowX: 'auto',
        }}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleClick}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src={uploadIcon} alt="upload icon" style={{ marginTop: '-3px', marginRight: '10px' }} />
          <p style={{ margin: '0', fontSize: '16px', color: '#666666' }}>{buttonText}</p>
        </div>
        <input
          type="file"
          id="file-input"
          style={{ display: 'none' }}
          multiple={multiple}
          onChange={handleFileInputChange}
        />
      </div>

      {/* Image preview section */}
      {previewImages.length > 0 && (
        <div style={{ overflowY: 'auto' }}>
          <h4>Preview:</h4>
          <div style={{ display: 'flex' }}>
            {previewImages.map((image, index) => (
              <div key={index} style={{ position: 'relative', marginRight: '10px' }}>
                {image.type.startsWith('image/') ? (
                  <img
                    src={URL.createObjectURL(image)}
                    alt={`Preview ${index}`}
                    style={{ maxWidth: '100px', maxHeight: '120px', marginRight: '10px' }}
                  />
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img src={folderSvg} alt="Folder Icon" style={{ marginBottom: '5px' }} /> {/* Folder icon */}
                    <div style={{ maxWidth: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {image.name}
                    </div>{' '}
                    {/* File name */}
                  </div>
                )}
                <button
                  style={{
                    position: 'absolute',
                    top: '-5px',
                    right: '-1px',
                    padding: '4px',
                    background: 'red',
                    color: 'white',
                    width: '25px',
                    borderRadius: '50%',
                    border: 'none',
                  }}
                  onClick={() => handleRemoveImage(image.name)}
                >
                  X
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUploader;
