import React, { CSSProperties } from 'react';
import mainTheme from '../../theme/theme';
import PropTypes from 'prop-types';

interface PrimaryButtonProps {
  children: React.ReactNode;
  style?: CSSProperties;
  onClick?: () => void;
}

function PrimaryButton({ children, style, onClick }: PrimaryButtonProps) {
  const defaultStyles = {
    backgroundColor: mainTheme.colors.primary,
    color: mainTheme.colors.fontSecondary,
    borderRadius: '8px',
    padding: '10px 20px',
    fontSize: '18px',
    fontFamily: mainTheme.typography.mainFont,
    fontWeight: mainTheme.fontWeight.medium,
    border: 'none',
    // width: '450px',
    height: '60px',
  };

  const buttonStyles = {
    ...defaultStyles,
    ...style,
  };
  return (
    <div>
      <button className="primary-button" style={buttonStyles} onClick={onClick}>
        {children}
      </button>
    </div>
  );
}

PrimaryButton.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  onclick: PropTypes.func,
};

export default PrimaryButton;
