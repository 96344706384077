import React, { CSSProperties } from 'react';
import PropTypes from 'prop-types';
import mainTheme from '../../theme/theme';

interface Props {
  number: string;
  para: string;
  contentWidth: string;
  style?: CSSProperties;
}

function MilestoneCard({ number, para, contentWidth, style }: Props) {
  const containerStyles = {
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    fontFamily: mainTheme.typography.mainFont,
  };

  const leftContentStyles = {
    fontSize: '40px',
    color: mainTheme.colors.primary,
    fontWeight: mainTheme.fontWeight.medium,
  };

  const rightContentStyles = {
    flex: '1',
    fontSize: '18px',
    fontWeight: mainTheme.fontWeight.medium,
    maxWidth: contentWidth || '98px',
    marginLeft: '10px',
  };

  const contentStyle = {
    ...containerStyles,
    ...style,
  };

  return (
    <div style={contentStyle}>
      <div style={leftContentStyles}>{number}</div>
      <div style={rightContentStyles}>{para}</div>
    </div>
  );
}

MilestoneCard.propTypes = {
  number: PropTypes.string.isRequired,
  para: PropTypes.string.isRequired,
  contentWidth: PropTypes.string,
  style: PropTypes.object,
};

export default MilestoneCard;
