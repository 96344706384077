import React, { useState, useEffect, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../redux/store/store';
import { checkToken } from '../../redux/features/admin/auth/authSlice';

const AdminProtectedRoute: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [sessionExpired, setSessionExpired] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  const handleAuth = async () => {
    const action = await dispatch(checkToken());
    const { type, payload } = action;

    if (type.endsWith('/rejected')) {
      setSessionExpired(true);
      console.log(payload);
    } else if (type.endsWith('/fulfilled')) {
      setSessionExpired(false);
      console.log(payload);
    }
  };

  useEffect(() => {
    handleAuth();
  }, [dispatch]);

  useEffect(() => {
    if (sessionExpired) {
      navigate('/admin/login');
    }
  }, [sessionExpired, history]);

  return <>{sessionExpired ? null : children}</>;
};

export default AdminProtectedRoute;
