import './SideHeader.css';
import { IoClose } from 'react-icons/io5';
import { useState } from 'react';
import mainTheme from '../../../theme/theme';

const SideHeader = () => {
  const [showMenu, setShowMenu] = useState(true);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return showMenu ? (
    <header className="side__header">
      <nav className="nav nav__container">
        <div className="nav__menu" id="nav-menu">
          <ul className="nav__list">
            <li className="nav__item">
              <a href="/" className="nav-link" style={styles.link}>
                Home
                {location.pathname === '/' ? <div className="selected-nav"></div> : null}
              </a>
            </li>
            <li className="nav__item">
              <a href="about" className="nav-link" style={styles.link}>
                About Us
                {location.pathname === '/about' ? <div className="selected-nav"></div> : null}
              </a>
            </li>
            <li className="nav__item">
              <a href="services" className="nav-link" style={styles.link}>
                Services
                {location.pathname === '/services' ? <div className="selected-nav"></div> : null}
              </a>
            </li>
            <li className="nav__item">
              <a href="gallery" className="nav-link" style={styles.link}>
                Gallery
                {location.pathname === '/gallery' ? <div className="selected-nav"></div> : null}
              </a>
            </li>
            <li className="contact-btn">
              <a href="contact" className="nav-link" style={styles.link}>
                Contact Us
                {location.pathname === '/contact' ? <div className="selected-nav"></div> : null}
              </a>
            </li>
          </ul>
          <div className="nav__close" id="nav-close" onClick={toggleMenu}>
            <IoClose />
          </div>
        </div>
      </nav>
    </header>
  ) : null;
};

const styles = {
  link: {
    color: mainTheme.colors.fontPrimary,
    fontFamily: mainTheme.typography.mainFont,
    fontWeight: mainTheme.fontWeight.medium,
  },
};

export default SideHeader;
