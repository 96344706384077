import mainTheme from '../../../../theme/theme';
import Carousel from '../../../../components/carousel/Carousel';
import bgCircle from '../../../../assets/images/bg.svg';
import './Testimonial.css';

function TestimonialsSection() {
  return (
    <div className="testimonials-container" style={styles.container}>
      <h2 className="testimonials-heading" style={styles.heading}>
        What Our Clients Say
      </h2>
      <div className="carousel-container">

      <Carousel />
      </div>
      <img 
        src={bgCircle} 
        alt="Background Circle"
        style={{
          zIndex: '-1',
          position:'absolute',
          height: '300px',
          bottom: -1390,
          left: 0,
          transform: 'rotate(-40deg)',
         }} />
    </div>
  );
}

export default TestimonialsSection;

const styles = {
  container: {
    backgroundColor: `rgba(${parseInt(mainTheme.colors.primary.slice(1, 3), 16)}, ${parseInt(
      mainTheme.colors.primary.slice(3, 5),
      16,
    )}, ${parseInt(mainTheme.colors.primary.slice(5, 7), 16)}, 0.05)`,
    fontFamily: mainTheme.typography.mainFont,
  },
  heading: {
    color: mainTheme.colors.primary,
    fontWeight: mainTheme.fontWeight.medium,
  },
};
